define("flaneur/pods/components/metabase-embed/component", ["exports", "@glimmer/component", "jsonwebtoken", "flaneur/config/environment", "fetch", "ember-component-css/pod-names"], function (_exports, _component, _jsonwebtoken, _environment, _fetch, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MetabaseEmbedComponent = (_dec = Ember._tracked, (_class = class MetabaseEmbedComponent extends _component.default {
    get styleNamespace() {
      return _podNames.default['metabase-embed'];
    }

    get hasIframeURL() {
      return typeof this.iframeURL !== 'undefined';
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "iframeURL", _descriptor, this);

      this.createIframe();
    }

    async createIframe() {
      const secrets = await this.getSecrets();
      const payload = {
        resource: {
          dashboard: this.args.dashboard
        },
        params: _objectSpread({}, this.args.params)
      };

      const token = _jsonwebtoken.default.sign(payload, secrets.METABASE_SECRET);

      const iframeURL = this.createIframeUrl(token);
      this.iframeURL = iframeURL;
    }

    async getSecrets() {
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const url = `${configURL}/api/v2/brain/secrets`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });
      const {
        secrets
      } = await res.json();
      return secrets;
    }

    createIframeUrl(token) {
      const path = [_environment.default.METABASE_SITE_URL, 'embed/dashboard', token];
      const url = path.join('/');
      return `${url}#bordered=false&titled=false`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "iframeURL", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MetabaseEmbedComponent;
});