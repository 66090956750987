define("flaneur/models/callout-banner/index", ["exports", "fetch", "@datadog/browser-rum", "flaneur/config/environment"], function (_exports, _fetch, _browserRum, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CalloutBanner {
    constructor({
      title,
      body,
      icon,
      enabled,
      updated_at
    }) {
      this.title = title;
      this.body = body;
      this.icon = icon;
      this.enabled = enabled;
      this.updated_at = updated_at;
    }

    static async find() {
      try {
        const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
        const url = `${configURL}/api/v2/static/flaneur-callout-banner.json`;
        const res = await (0, _fetch.default)(url, {
          method: 'GET',
          credentials: 'include',
          cache: false
        });

        if (!res.ok) {
          throw new Error('unable to get callout banner');
        }

        const body = await res.json();

        if (body.err) {
          throw new Error(body.err);
        }

        return new CalloutBanner(body.static.callout_banner);
      } catch (error) {
        _browserRum.datadogRum.addError(error);
      }
    }

  }

  _exports.default = CalloutBanner;
});