define("flaneur/models/currency-statement/index", ["exports", "fetch", "flaneur/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CurrencyStatement {
    // Distinguish from legacy statements
    constructor({
      statement_number,
      statement_url,
      currency,
      total_payout,
      created_at
    }) {
      _defineProperty(this, "isCurrencyStatement", true);

      this.statement_number = statement_number;
      this.statement_url = statement_url;
      this.currency = currency;
      this.total_payout = total_payout;
      this.created_at = new Date(created_at);
    }

    get previewUrl() {
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      return `${configURL}/api/v2/currency_statements/statement?number=${this.statement_number}`;
    }

    static async findByBoutique(boutiqueId) {
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const url = `${configURL}/api/v2/currency_statements/historic/by:boutique/${boutiqueId}?skip_links=true`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('error finding currency statements by boutique');
      }

      const {
        currency_statements,
        meta
      } = await res.json();
      return {
        currencyStatements: currency_statements.map(statement => new CurrencyStatement(statement)),
        meta
      };
    }

  }

  _exports.default = CurrencyStatement;
});