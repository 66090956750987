define("flaneur/models/boutique-restock-information/index", ["exports", "fetch", "query-string", "flaneur/models/model", "flaneur/config/environment", "flaneur/models/boutique-restock-information/validations", "flaneur/enums/boutique-restock-information-status", "flaneur/enums/boutique-restock-information-reason"], function (_exports, _fetch, _queryString, _model, _environment, _validations, _boutiqueRestockInformationStatus, _boutiqueRestockInformationReason) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BoutiqueRestockInformation = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class BoutiqueRestockInformation extends _model.default {
    constructor(boutiqueRestockInformation = {}) {
      super();

      _initializerDefineProperty(this, "boutique", _descriptor, this);

      _initializerDefineProperty(this, "product", _descriptor2, this);

      _initializerDefineProperty(this, "status", _descriptor3, this);

      _initializerDefineProperty(this, "quantity", _descriptor4, this);

      _initializerDefineProperty(this, "lead_date", _descriptor5, this);

      _initializerDefineProperty(this, "reason", _descriptor6, this);

      _initializerDefineProperty(this, "notes", _descriptor7, this);

      const {
        _id,
        boutique,
        product,
        status,
        quantity,
        lead_date,
        reason = {
          type: null,
          notes: ''
        },
        notes
      } = boutiqueRestockInformation;
      this._id = _id;
      this.boutique = boutique;
      this.product = product;
      this.status = status;
      this.quantity = quantity;
      this.lead_date = lead_date;
      this.reason = reason;
      this.notes = notes;
    }

    get isRestocking() {
      return this.status === _boutiqueRestockInformationStatus.default.RESTOCKING;
    }

    get isNotAvailable() {
      return this.status === _boutiqueRestockInformationStatus.default.NOT_AVAILABLE;
    }

    get fieldsToValidate() {
      if (this.isRestocking) {
        return _validations.restockingValidations;
      }

      if (this.reason.type === _boutiqueRestockInformationReason.default.OTHER) {
        return _validations.reasonOtherValidations;
      }

      return {};
    }

    static async findById(id) {
      const queryParams = _queryString.default.stringify({
        skip_links: true
      });

      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const res = await (0, _fetch.default)(`${configURL}/api/v2/boutique_restock_information/${id}?${queryParams}`, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        return null;
      }

      try {
        const {
          boutique_restock_information
        } = await res.json();
        const [boutiqueRestockInformation] = boutique_restock_information;
        return new BoutiqueRestockInformation(boutiqueRestockInformation);
      } catch (e) {
        throw new Error('error finding boutique restock information by id');
      }
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread(_objectSpread({}, query), {}, {
        skip_links: true
      }));

      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const url = `${configURL}/api/v2/boutique_restock_information?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        return [];
      }

      const {
        boutique_restock_information: allBoutiqueRestockInformation = [],
        meta = {
          total: 0
        }
      } = await res.json();
      return {
        boutiqueRestockInformation: allBoutiqueRestockInformation.map(boutiqueRestockInformation => new BoutiqueRestockInformation(boutiqueRestockInformation)),
        meta
      };
    }

    async create() {
      if (!this.isValid) {
        return Ember.RSVP.reject({
          errors: this.validations
        });
      }

      const payload = {
        boutique: this.boutique,
        product: this.product,
        status: this.status,
        quantity: this.quantity,
        lead_date: this.lead_date,
        reason: this.reason
      };
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const url = `${configURL}/api/v2/boutique_restock_information`;
      const res = await (0, _fetch.default)(url, {
        method: 'POST',
        credentials: 'include',
        cache: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        throw new Error('error creating boutique restock information');
      }

      try {
        const {
          boutique_restock_information
        } = await res.json();
        const [boutiqueRestockInformation] = boutique_restock_information;
        Ember.setProperties(this, boutiqueRestockInformation);
        return this;
      } catch (e) {
        throw new Error('error creating boutique restock information');
      }
    }

    async update() {
      if (!this.isValid) {
        return Ember.RSVP.reject({
          errors: this.validations
        });
      }

      const payload = {
        status: this.status,
        quantity: this.quantity,
        lead_date: this.lead_date,
        reason: this.reason
      };
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const url = `${configURL}/api/v2/boutique_restock_information/${this._id}?skip_links=true`;
      const res = await (0, _fetch.default)(url, {
        method: 'PUT',
        credentials: 'include',
        cache: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        throw new Error('error updating boutique restock information');
      }

      try {
        const {
          boutique_restock_information
        } = await res.json();
        const [boutiqueRestockInformation] = boutique_restock_information;
        Ember.setProperties(this, boutiqueRestockInformation);
        return this;
      } catch (e) {
        throw new Error('error updating boutique restock information');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "product", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lead_date", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "reason", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "notes", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BoutiqueRestockInformation;
});