define("flaneur/mixins/request", ["exports", "jquery", "flaneur/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitize = sanitize;
  _exports.default = void 0;

  function sanitize(model, keys = []) {
    const obj = {};
    const toFilter = ['container', 'errors', 'validators', 'validations', 'dependentValidationKeys', '__validationsMixinCount__', 'owner', ...keys];

    for (const key in model) {
      if (Object.prototype.hasOwnProperty.call(model, key) && toFilter.indexOf(key) < 0 && key.indexOf('OWNER') === -1) {
        obj[key] = model[key];
      }
    }

    return obj;
  }

  var _default = Ember.Mixin.create({
    /**
     * Request method
     * @param  {Object} params Parameters
     * @return {Object}      Promise
     */
    request: function (params = {}) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: params.url,
          data: params.data,
          type: params.method,
          headers: params.headers ? params.headers : {},
          contentType: 'application/json; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          cache: false
        }).done(resolve).fail(reject);
      }).catch(err => {
        const errorObj = new Error(`${err.status}: ${err.statusText}`);
        errorObj.status = err.status;
        errorObj.responseJSON = err.responseJSON;
        errorObj.extra = {
          method: params.method,
          url: params.url,
          headers: params.headers || {},
          data: params.data
        };
        throw errorObj;
      });
    },

    /**
     * Returns the apiURL
     * @param  {String} endpoint Endpoint (products)
     * @return {String}          Full URL
     */
    apiURL(endpoint) {
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      return `${configURL}/api/v2/` + (endpoint ? endpoint : '');
    },

    /**
     * Returns the thirdPartyAuthServiceApiURL
     * @param  {String} endpoint Endpoint (token)
     * @return {String}          Full URL
     */
    thirdPartyAuthServiceApiURL(endpoint) {
      return `${_environment.default.thirdPartyAuthServiceApiURL}/` + (endpoint ? endpoint : '');
    },

    /**
     * Remove all the ember validation BS to avoid circular json errors
     * @param {Array} keys Keys to sanitize on top of the validation ones
     * @return {Object} Object
     */
    sanitize(keys = []) {
      return sanitize(this, keys);
    },

    /**
     * Array of error messages
     * @return {Array} Messages
     */
    errorMessages: Ember.computed('validations.{isValid,errors,messages}', function () {
      if (this.validations.isValid) {
        return [];
      }

      return this.validations.messages;
    })
  });

  _exports.default = _default;
});