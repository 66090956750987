define("flaneur/models/statement/index", ["exports", "fetch", "flaneur/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Statement {
    constructor({
      statement_number,
      statement_url,
      currency,
      total_payout,
      created_at
    }) {
      this.statement_number = statement_number;
      this.statement_url = statement_url;
      this.currency = currency;
      this.total_payout = total_payout;
      this.created_at = new Date(created_at);
    }

    static async findByBoutique(boutiqueId) {
      const configURL = localStorage.getItem('usePromenadeV2') ? _environment.default.apiURLV2 : _environment.default.apiURL;
      const url = `${configURL}/api/v2/statements/historic/by:boutique/${boutiqueId}?skip_links=true`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('error finding statements by boutique');
      }

      const {
        statements,
        meta
      } = await res.json();
      return {
        statements: statements.map(statement => new Statement(statement)),
        meta
      };
    }

  }

  _exports.default = Statement;
});